import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Translator } from "@sharedV11/services/translator/translator.service";

@Component({
  selector: "app-input-filter",
  templateUrl: "./input-filter.component.html",
  styleUrls: ["./input-filter.component.scss"],
})
export class InputFilterComponent implements OnInit {
  @Input() formControlText: any;
  @Input() placeHolderTranslation: string;
  @Input() label: string;
  @Input() list: any[] = [];
  // text show in input
  @Input() textShow: any;
  //data input
  @Input() dataInput: any;

  //API data
  @Input() data: any;
  @Input() required: any;
  @Input() disabled: any;

  @Output() close = new EventEmitter();
  @Output() selected = new EventEmitter();

  listCopy: any[];
  idList;
  editorRegistroForm: FormGroup;

  intervalId;
  translation: any;

  constructor(
    private formBuilder: FormBuilder,
    private _cds: ChangeDetectorRef,
    private translator: Translator,
  ) {}
  ngAfterViewInit() {
    if (document.getElementById(this.formControlText)) {
      document
        .getElementById(this.formControlText)
        .setAttribute("list", this.idList);
    }
    // this.addEvent();
    this.showData();
    this.controlErrors();
  }
  ngOnInit(): void {
    this.translation = this.translator.GetTranslations();
    this.initlist();
  }

  OnChange() {
    if (this.listCopy && this.listCopy.length > 0) {
      let valName = (<HTMLInputElement>(
        document.getElementById(this.formControlText)
      )).value;
      //let val= (<HTMLInputElement>document.getElementById(this.formControlText+"-hidden")).value
      let val = this.editorRegistroForm.get("answerHidden").value;
      let find = this.listCopy.find(
        (find) =>
          find[this.textShow].trim().replace("  ", " ") ==
          valName.trim().replace("  ", " ")
      );

      if (!find) {
        this.editorRegistroForm.controls["answerHidden"].setValue("");
        if (this.editorRegistroForm.controls["answer"].value.trim() != "") {
          this.editorRegistroForm.controls["answer"].setErrors({
            incorrect: true,
          });
        }
        //this.editorRegistroForm.controls["answer"].updateValueAndValidity()
        //(<HTMLInputElement>document.getElementById(this.formControlText)).innerHTML=""
      } else {
        let valueFind = find[this.dataInput];

        this.editorRegistroForm.get("answerHidden").setValue(valueFind);
        this.editorRegistroForm.controls["answer"].setErrors(null);
      }
      val = this.editorRegistroForm.get("answerHidden").value;

      this.selected.emit({ name: this.formControlText, value: val });
    } else {
      this.editorRegistroForm.controls["answerHidden"].setValue("");
      this.selected.emit({ name: this.formControlText, value: "-1" });
    }
  }

  initlist(list?) {
    if (list) this.list = list;
    if (this.list && this.list.length > 0) {
      this.listCopy = [...this.list];
    }
    this.idList = this.formControlText + "-list";
    //document.getElementById(this.formControlText).setAttribute("list", this.idList)
    this.generateForm();
  }

  controlErrors() {
    this.editorRegistroForm.markAllAsTouched();
    this.editorRegistroForm.updateValueAndValidity();
    this._cds.detectChanges();
  }

  callbackMethods() {
    return {
      markFormAsInvalid: this.markFormAsInvalid.bind(this),
    }
  }

  markFormAsInvalid(control: string, errors: any) {
    this.editorRegistroForm.controls[control].setErrors(errors);
  }

  showData() {
    if (this.disabled) {
      this.editorRegistroForm.get("answer").disable();
    }
    if (
      this.data &&
      this.data != 0 &&
      this.listCopy &&
      this.listCopy.length > 0
    ) {
      let value = this.listCopy.find(
        (find) => find[this.dataInput] == this.data
      );
      if (value) {
        value = value[this.textShow];
        //(<HTMLInputElement>document.getElementById(this.formControlText)).value = value
        this.editorRegistroForm.controls["answer"].setValue(value);
      }
      this.editorRegistroForm.controls["answerHidden"].setValue(this.data);
    }
    this._cds.detectChanges();
  }

  generateForm() {
    this.editorRegistroForm = this.formBuilder.group({
      answer: ["", this.required == "true" ? [Validators.required] : []],
      // answer:["",[Validators.required]],
      answerHidden: [""],
    });
  }

  intervaloClose() {
    /* if(this.interval){
      clearInterval(this.interval)
    } */
    let thisobj = this;

    setTimeout(function () {
      thisobj.closeInput();
    }, 1000);
    //clearInterval(this.intervalId)
  }
  closeInput() {
    //clearInterval(this.intervalId)
    if (this.listCopy && this.listCopy.length > 0) {
      let valName = (<HTMLInputElement>(
        document.getElementById(this.formControlText)
      )).value;
      //let val= (<HTMLInputElement>document.getElementById(this.formControlText+"-hidden")).value
      let val = this.editorRegistroForm.get("answerHidden").value;
      let find = this.listCopy.find(
        (find) =>
          find[this.textShow].trim().replace("  ", " ") ==
          valName.trim().replace("  ", " ")
      );

      if (!find) {
        this.editorRegistroForm.controls["answerHidden"].setValue("");
        if (this.editorRegistroForm.controls["answer"].value.trim() != "") {
          this.editorRegistroForm.controls["answer"].setErrors({
            incorrect: true,
          });
        }
        //this.editorRegistroForm.controls["answer"].updateValueAndValidity()
        //(<HTMLInputElement>document.getElementById(this.formControlText)).innerHTML=""
      } else {
        let valueFind = find[this.dataInput];

        this.editorRegistroForm.get("answerHidden").setValue(valueFind);
        this.editorRegistroForm.controls["answer"].setErrors(null);
      }
      val = this.editorRegistroForm.get("answerHidden").value;

      this.close.emit({ name: this.formControlText, value: val, methods: this.callbackMethods() });
    } else {
      this.editorRegistroForm.controls["answerHidden"].setValue("");
      this.close.emit({ name: this.formControlText, value: "-1", methods: this.callbackMethods() });
    }
  }

  addEvent() {
    let thisobj = this;
    document.querySelectorAll("input[list]").forEach((hElement) => {
      hElement.addEventListener("input", function (e) {
        var input = e.target as HTMLTextAreaElement,
          listCopy = input.getAttribute("list"),
          options: any = Array.from(
            document.querySelectorAll("#" + listCopy + " option")
          ),
          hiddenInput = <HTMLInputElement>(
            document.getElementById(input.getAttribute("id") + "-hidden")
          ),
          label = input.value;

        hiddenInput.nodeValue = label;
        let find = thisobj.findOption(label);
        if (find) {
          hiddenInput.value = find[thisobj.dataInput];
          thisobj.editorRegistroForm
            .get("answerHidden")
            .setValue(find[thisobj.dataInput]);
        }
        // thisobj.closeInput();
        /* for(var i = 0; i < options.length; i++) {
            var option = options[i];
            option.innerHTML = option.innerHTML.trim()
            if(option.innerHTML == label) {
              hiddenInput.value = option.getAttribute('title');
                break;
            }
        } */
      });
    });
  }

  get f() {
    return this.editorRegistroForm.controls;
  }

  selectString(str) {
    if (this.listCopy && this.listCopy.length > 0) {
      let valName = str;
      let val = this.editorRegistroForm.get("answerHidden").value;

      let find = this.listCopy.find(
        (find) =>
          find[this.textShow].trim().replace("  ", " ") ==
          valName.trim().replace("  ", " ")
      );

      if (!find) {
        this.editorRegistroForm.controls["answerHidden"].setValue("");
        if (this.editorRegistroForm.controls["answer"].value.trim() != "") {
          this.editorRegistroForm.controls["answer"].setErrors({
            incorrect: true,
          });
        }
        //this.editorRegistroForm.controls["answer"].updateValueAndValidity()
        //(<HTMLInputElement>document.getElementById(this.formControlText)).innerHTML=""
      } else {
        let valueFind = find[this.dataInput];

        this.editorRegistroForm.controls["answer"].setValue(
          find[this.textShow]
        );
        this.editorRegistroForm.get("answerHidden").setValue(valueFind);

        /*  if(this.editorRegistroForm.controls["answer"].value.trim()!=""){
          this.editorRegistroForm.controls["answer"].setErrors(null);
        } */
        this.editorRegistroForm.controls["answer"].setErrors(null);
        //this.editorRegistroForm.controls["answerHidden"].setValue(val);
        this.selected.emit(true);
      }
      val = this.editorRegistroForm.get("answerHidden").value;

      this.close.emit({ name: this.formControlText, value: val, methods: this.callbackMethods() });
    } else {
      this.editorRegistroForm.controls["answerHidden"].setValue("");
      this.close.emit({ name: this.formControlText, value: "-1", methods: this.callbackMethods() });
    }
  }

  findOption(label) {
    let find = this.listCopy.find(
      (find) =>
        find[this.textShow].trim().replace("  ", " ") ==
        label.trim().replace("  ", " ")
    );

    return find;
  }
}

import { Component, Input, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup
} from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { ConectarApiService } from "@sharedV11/services/api/connection/conectar-api.service";
import { EspectaculosService } from "@sharedV11/services/espectaculos/espectaculos.service";
import { Translator } from "@sharedV11/services/translator/translator.service";
import * as d3 from "d3";
import moment from "moment";
import { InfoMapasService } from "../selector-butacas/servicios/info-mapas.service";
import { EntradasService } from "../selector-butacas/servicios/entradas.service";


@Component({
  selector: "customan-sesiones-mapa",
  templateUrl: "./sesiones-mapa.component.html",
  styleUrls: ["./sesiones-mapa.component.css"],
})
export class SesionesMapaComponent implements OnInit {
  [x: string]: any;
  loading = false;
  editorButacasForm: FormGroup;
  editorButacasSinNumerarForm: FormGroup;
  numeradas: boolean;
  nonumeradas: boolean;
  svg: any;
  recinto: any;
  idsButacasMarcadas = [];
  getButacasRes: any;
  getEstadosButacas: any;
  getEstadosButacasPorRecinto: any;
  listaEstadosButacas: any;
  listaTiposButacas: any;
  listaRecintos: any;
  divTiposButaca = [""];
  recintoNoNumeradoSeleccionado: 0;
  element = null;
  previousElement = null;
  currentY = 0;
  currentX = 0;
  originX = 0;
  originY = 0;

  @Input() GrupoRecinto: string;
  @Input() chPathMapaSVG: string;
  @Input() horaInicioSesion: string;
  @Input() fechaSesion: string;
  @Input() espectaculoId: string;
  @Input() sesionId: string;
  @Input() contextual: boolean = true;
  @Input() svgheight: string = "450";
  @Input() svgwidth: string = "560";
  clickTime: any;
  attributesText: any;
  rectElement: any;
  origink = 2.441893025338287;
  originx = 0.85381396380967;
  originy = 0.029665907032;
  originTransform: any = { k: this.origink, x: this.originx, y: this.originy };
  lastTransform: any = { k: this.origink, x: this.originx, y: this.originy };
  multiselecction = false;
  dragx = 0;
  dragy = 0;
  svgDrawing;
  pointerOrigin;
  point;
  drawRectToSelect;
  raster = 10;
  svg_children;
  butacasCopia;
  translation;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private infoMapasService: InfoMapasService,
    private espectaculosService: EspectaculosService,
    private servicioAPI: ConectarApiService,
    private _snackBar: MatSnackBar,
    private translator: Translator,
    private entradasService: EntradasService,
  ) {}
  ngOnInit() {
    this.initializeTranslate();

    this.svg_children = null;
    this.pointerOrigin = null;
    this.loading = false;
    if (document.querySelector("#temp_selection"))
      document
        .querySelector("#temp_selection")
        .parentNode.removeChild(document.querySelector("#temp_selection"));
    if (this.drawRectToSelect) this.drawRectToSelect = null;

    this.clickTime = d3.select("#clicktime");
    this.attributesText = d3.select("#attributestext");

    this.espectaculosService.sesionSeleccionada.GrupoRecinto =
      this.GrupoRecinto;
    this.espectaculosService.sesionSeleccionada.espectaculoId = parseInt(
      this.espectaculoId
    );
    this.espectaculosService.sesionSeleccionada.sesionId = parseInt(
      this.sesionId
    );
    this.espectaculosService.sesionSeleccionada.fechaSesion = this.fechaSesion;
    this.espectaculosService.sesionSeleccionada.horaInicioSesion =
      this.horaInicioSesion;
    this.espectaculosService.sesionSeleccionada.chPathMapaSVG =
      this.chPathMapaSVG;

    if (!this.espectaculosService.sesionSeleccionada.espectaculoId) {
      const urlSections = this.router.url.split("/");
      urlSections.pop();
      urlSections.pop();
      urlSections.pop();
      urlSections.pop();
      urlSections.pop();

      let backUrl = "";

      urlSections.forEach((section) => {
        backUrl += section + "/";
      });
      this.router.navigate([backUrl]);
    }
    this.editorButacasForm = this.formBuilder.group({
      EditarEstado: [{ value: "", disabled: false }],
      EditarTipo: [{ value: "", disabled: false }],
    });

    this.editorButacasSinNumerarForm = this.formBuilder.group({
      EditarEstado: [{ value: "", disabled: false }],
      EditarTipo: [{ value: "", disabled: false }],
      cantidadNORMAL: [{ value: 0, disabled: false }],
      cantidadVREDUCIDA: [{ value: 0, disabled: false }],
      cantidadMINUSVALIDO: [{ value: 0, disabled: false }],
      cantidadBLOQUEOS: [{ value: 0, disabled: false }],
      cantidadRESERVA: [{ value: 0, disabled: false }],
      cantidadVIP: [{ value: 0, disabled: false }],
      lblNORMAL: [{ value: "0", disabled: true }],
      lblBLOQUEOS: [{ value: "0", disabled: true }],
      lblVREDUCIDA: [{ value: "0", disabled: true }],
      lblMINUSVALIDO: [{ value: "0", disabled: true }],
      lblRESERVA: [{ value: "0", disabled: true }],
      lblVIP: [{ value: "0", disabled: true }],
    });

    this.numeradas = false;
    this.nonumeradas = false;
    this.servicioAPI
      .GetTipoButacasPorRecintoAsync(
        this.espectaculosService.sesionSeleccionada.espectaculoId,
        this.espectaculosService.sesionSeleccionada.fechaSesion,
        this.espectaculosService.sesionSeleccionada.horaInicioSesion,
        this.espectaculosService.sesionSeleccionada.GrupoRecinto
      )
      .subscribe((tipoRecinto) => {
        if (tipoRecinto === undefined && tipoRecinto.DatosListas.length === 0) {
          this.listaRecintos = undefined;
        } else {
          this.listaRecintos = tipoRecinto.DatosResult;
        }
        this.servicioAPI
          .TraerDatosListasAsync("estadosbutaca")
          .subscribe((estadosbutaca) => {
            if (
              estadosbutaca === undefined &&
              estadosbutaca.DatosListas.length === 0
            ) {
              this.listaEstadosButacas = undefined;
            } else {
              this.listaEstadosButacas = estadosbutaca.DatosListas;
            }
            if (estadosbutaca !== undefined) {
              this.servicioAPI
                .TraerDatosListasAsync("tiposbutaca")
                .subscribe((tiposbutaca) => {
                  if (
                    tiposbutaca === undefined &&
                    tiposbutaca.DatosListas.length === 0
                  ) {
                    this.listaTiposButacas = undefined;
                  } else {
                    this.listaTiposButacas = tiposbutaca.DatosListas;
                  }
                  if (tiposbutaca !== undefined) {
                    this.listaTiposButacas.forEach((tipo) => {
                      this.divTiposButaca.push(tipo.Nombre);
                    });
                    this.loadMap(
                      estadosbutaca.DatosListas,
                      tiposbutaca.DatosListas
                    );
                  }
                });
            }
          });
      });
  }

  initializeTranslate() {
    this.translation = this.translator.GetTranslations();
  }

  async loadMap(estadosbutaca: any, tiposbutaca: any) {
    let fecha = this.espectaculosService.sesionSeleccionada.fechaSesion;
    await this.servicioAPI
      .getButacasAsync(
        this.espectaculosService.sesionSeleccionada.GrupoRecinto,
        this.espectaculosService.sesionSeleccionada.sesionId,
        "",
        ""
      )
      .then((res) => {
        if (res === undefined && res.DatosResult.sesiones.length === 0) {
          this.getButacasRes = undefined;
        } else {
          this.getButacasRes = res;
        }
      });

    await this.servicioAPI.EstadosButacasAsync().then((estados) => {
      if (estados === undefined && estados.DatosResult.Estados.length === 0) {
        this.getEstadosButacas = undefined;
      } else {
        this.getEstadosButacas = estados.DatosResult.Estados;
      }
    });

    await this.servicioAPI
      .EstadosButacasPorRecintoAsync(
        this.espectaculosService.sesionSeleccionada.espectaculoId,
        fecha,
        this.espectaculosService.sesionSeleccionada.horaInicioSesion,
        this.espectaculosService.sesionSeleccionada.GrupoRecinto
      )
      .then((estados) => {
        if (estados === undefined && estados.DatosResult.Estados.length === 0) {
          this.getEstadosButacasPorRecinto = undefined;
        } else {
          this.getEstadosButacasPorRecinto = estados.DatosResult.Estados;
        }
      });

    const tooltip = d3
      .select("body")
      .append("div")
      .attr("class", "tooltip")
      .attr("id", "tooltip")
      .style("opacity", 0);

    this.loading = true;
    this.infoMapasService
      .getSVG(
        this.espectaculosService.sesionSeleccionada.chPathMapaSVG.split(".")[0]
      )
      .subscribe(async (data: string) => {
        this.loading = false;
        const espacio = {
          width: parseInt(this.svgwidth),
          height: parseInt(this.svgheight),
        };

        this.svg = d3
          .select("#mapaButacas")
          .attr("preserveAspectRatio", "xMidYMid meet")
          .attr("viewBox", "0 0 " + 3500 + " " + 3500)
          .attr("width", espacio.width)
          .attr("height", espacio.height);

        let _this = this;
        d3.selectAll("g").remove();
        this.recinto = this.svg
          .append("g")
          .append("g")
          .attr("id", "recinto")
          .attr("width", espacio.width)
          .attr("height", espacio.height)
          .attr(
            "transform",
            "translate(" +
              this.lastTransform.x +
              "," +
              this.lastTransform.y +
              ") scale(" +
              this.lastTransform.k +
              ")"
          )
          .on("click", function (d) {
            d3.select(this).style("cursor", "default");
          })
          .on("wheel", function (d) {
            if (!this.multiselecction) {
              d3.select(this).style("cursor", "zoom-in");
              var direction = d3.event.wheelDelta < 0 ? "down" : "up";
              if (direction == "down")
                d3.select(this).style("cursor", "zoom-out");
              else d3.select(this).style("cursor", "zoom-in");

              _this.setZoom(direction);
            }
          });

        this.recinto.attr(
          "transform",
          "translate(" +
            this.lastTransform.x +
            "," +
            this.lastTransform.y +
            ") scale(" +
            this.lastTransform.k +
            ")"
        );
        this.recinto.style("opacity", 1);

        let _that = this;
        var dragBehavior = d3
          .drag()
          .on("drag", function () {
            if (_that.multiselecction) {
            } else {
              var p = d3.mouse(this);
              d3.select(this).style("cursor", "move");
              _this.lastTransform.x += (p[0] - _this.dragx) * 0.02;
              _this.lastTransform.y += (p[1] - _this.dragy) * 0.02;
              _this.recinto.attr(
                "transform",
                "translate(" +
                  _this.lastTransform.x +
                  "," +
                  _this.lastTransform.y +
                  ") scale(" +
                  _this.lastTransform.k +
                  ")"
              );
            }
          })
          .on("start", function () {
            if (_that.multiselecction) {
            } else {
              d3.select(this).style("cursor", "move");
              var p = d3.mouse(this);
              _this.dragx = p[0];
              _this.dragy = p[1];
            }
          })
          .on("end", function () {
            d3.select(this).style("cursor", "default");
            if (_that.multiselecction) {
            } else {
              var p = d3.mouse(this);
              _this.dragx = p[0];
              _this.dragy = p[1];
            }
          });

        this.svg.call(dragBehavior);

        const rec = document.getElementById("recinto");
        if (rec) {
          rec.insertAdjacentHTML("beforeend", data);
        }
        this.recinto.selectAll("circle").remove();
        this.infoMapasService
          .getButacas(
            this.espectaculosService.sesionSeleccionada.chPathMapaSVG.split(
              "."
            )[0]
          )
          .subscribe((butacas: [any]) => {
            for (let i = 0; i < butacas.length; ++i) {
              if (butacas[i].idRecintoButaca !== undefined) {
                for (
                  let o = 0;
                  o < this.getButacasRes.DatosResult.sesiones.length;
                  ++o
                ) {
                  if (
                    this.getButacasRes.DatosResult.sesiones[o] !== undefined &&
                    this.getButacasRes.DatosResult.sesiones[o]
                      .DistribucionGrafica !== undefined &&
                    butacas[i].idRecintoButaca ===
                      this.getButacasRes.DatosResult.sesiones[o]
                        .DistribucionGrafica
                  ) {
                    butacas[i].ButacaDDBB =
                      this.getButacasRes.DatosResult.sesiones[o];
                  }
                }
              }
            }
            butacas.forEach((butaca) => {
              if (
                butaca.ButacaDDBB !== undefined &&
                butaca.ButacaDDBB.idRecintoButaca !== undefined
              ) {
                let encontrado = false;
                this.listaRecintos.forEach((rec) => {
                  if (
                    rec.RecintoID === butaca.ButacaDDBB.RecintoId &&
                    rec.Numeradas === "0"
                  ) {
                    encontrado = true;
                  }
                });
                if (!encontrado) {
                  const c = this.recinto
                    .append("circle")
                    .attr("_pkid", butaca.ButacaDDBB.idRecintoButaca)
                    .style("fill", () => {
                      let colorestilo = "";
                      this.getEstadosButacas.forEach((estado) => {
                        if (
                          butaca.ButacaDDBB.Estado ===
                            estado.EstadoButaca.toString() &&
                          (estado.TipoButaca === null ||
                            butaca.ButacaDDBB.TipoButaca ===
                              estado.TipoButaca.toString())
                        ) {
                          colorestilo = estado.Color;
                        }
                      });
                      return colorestilo;
                    })
                    .attr("cx", butaca.cx)
                    .attr("cy", butaca.cy)
                    .attr("r", butaca.r)
                    .style("z-index", "800")
                    .on("mouseover", function () {
                      tooltip.style("opacity", 1);
                      tooltip
                        .html(function () {
                          let nombreestadobutaca = "";
                          let nombretipobutaca = "";
                          estadosbutaca.forEach((obj) => {
                            if (
                              obj.Id.toString() ===
                              butaca.ButacaDDBB.Estado.toString()
                            ) {
                              nombreestadobutaca = obj.Nombre;
                            }
                          });
                          tiposbutaca.forEach((obj) => {
                            if (
                              obj.Id.toString() ===
                              butaca.ButacaDDBB.TipoButaca.toString()
                            ) {
                              
                              nombretipobutaca = obj.Nombre;
                            }
                          });
                          return (
                            "Estado: " +
                            nombreestadobutaca +
                            " <br>TipoButaca: " +
                            nombretipobutaca + 
                            " <br>Fila: " +
                            butaca.dataNumFila +
                            " <br>Butaca: " +
                            butaca.dataNumButaca +
                            " <br>Recinto: " +
                            localStorage.getItem('chNombreRecinto')

                          );
                        })
                        .style("left", d3.event.pageX + 10 + "px")
                        .style("background", "rgba(255, 255, 204, 0.9)")
                        .style("top", d3.event.pageY - 28 + "px")
                        .style("user-select", "none") 
                        .style("pointer-events", "none");
                      var circle = d3.select(this);
                      var radio = circle.attr("r");
                      circle
                        .transition()
                        .duration(80)
                        .attr("r", parseFloat(radio) + 0.8);
                      circle.style("cursor", "pointer");
                    })
                    .on("mouseout", function (d) {
                      tooltip.style("opacity", 0);
                      var circle = d3.select(this);
                      circle.transition().duration(200).attr("r", 6);
                      circle.style("cursor", "pointer");
                    })
                    .on("click", () => {
                      this.numeradas = true;
                      if (this.nonumeradas) {
                        this.borraSeleccionSinRecarga();
                      }
                      this.nonumeradas = false;
                      if (
                        this.idsButacasMarcadas.includes(
                          butaca.ButacaDDBB.idRecintoButaca
                        )
                      ) {
                        this.idsButacasMarcadas.splice(
                          this.idsButacasMarcadas.indexOf(
                            butaca.ButacaDDBB.idRecintoButaca
                          ),
                          1
                        );
                        this.getEstadosButacas.forEach((estado) => {
                          if (
                            butaca.ButacaDDBB.Estado ===
                              estado.EstadoButaca.toString() &&
                            (estado.TipoButaca === null ||
                              butaca.ButacaDDBB.TipoButaca ===
                                estado.TipoButaca.toString())
                          ) {
                            const marcado = [
                              {
                                RecintoButacaIds:
                                  butaca.ButacaDDBB.idRecintoButaca,
                                idTarifa: 0,
                              },
                            ];
                            this.servicioAPI
                              .MarcarDesmarcarButacaAsync(marcado, 0)
                              .subscribe((respuesta) => {
                                if (
                                  respuesta !== undefined &&
                                  respuesta.DatosResult
                                ) {
                                  c.style("fill", estado.Color);
                                }
                              });
                          }
                        });
                      } else {
                        this.idsButacasMarcadas.push(
                          butaca.ButacaDDBB.idRecintoButaca
                        );
                        this.getEstadosButacas.forEach((element) => {
                          this.editorButacasForm = this.formBuilder.group({
                            EditarEstado: [
                              {
                                value: butaca.ButacaDDBB.Estado,
                                disabled: false,
                              },
                            ],
                            EditarTipo: [
                              {
                                value: butaca.ButacaDDBB.TipoButaca,
                                disabled: false,
                              },
                            ],
                          });
                        });
                        const marcado = [
                          {
                            RecintoButacaIds: butaca.ButacaDDBB.idRecintoButaca,
                            idTarifa: 0,
                            value: butaca.ButacaDDBB.TipoButaca,
                          },
                        ];
                        this.servicioAPI
                          .MarcarDesmarcarButacaAsync(marcado, 1)
                          .subscribe((respuesta) => {
                            if (
                              respuesta !== undefined &&
                              respuesta.DatosResult
                            ) {
                              c.style("fill", "blue");
                            }
                          });
                      }
                    });
                }
              }
            });
            this.listaRecintos.forEach((rec) => {
              if (rec.Numeradas === "0") {
                this.recinto
                  .selectAll('[id="R' + rec.RecintoID + '"]')
                  .style("stroke", function () {
                    return d3.select(this).style("fill");
                  })
                  .style("stroke-width", "2")
                  .on("mouseover", function () {
                    d3.select(this).style("fill-opacity", "0.6");
                  })
                  .on("mouseout", function () {
                    d3.select(this).style("fill-opacity", "1");
                  })
                  .on("click", () => {
                    this.recintoNoNumeradoSeleccionado = rec.RecintoID;
                    this.nonumeradas = true;
                    this.numeradas = false;
                    this.resetearCantidadesNoNumeradas();
                    this.borraSeleccionSinRecarga();
                    const form = this.editorButacasSinNumerarForm.value;
                    this.getEstadosButacasPorRecinto.forEach(
                      (estadosbutaca) => {
                        if (estadosbutaca.IDRecinto === rec.RecintoID) {
                          if (estadosbutaca.Descripcion === "NORMAL") {
                            this.editorButacasSinNumerarForm.patchValue({
                              lblNORMAL: estadosbutaca.NumButaca,
                            });
                          } else if (
                            estadosbutaca.Descripcion === "MINUSVALIDO"
                          ) {
                            this.editorButacasSinNumerarForm.patchValue({
                              lblMINUSVALIDO: estadosbutaca.NumButaca,
                            });
                          } else if (estadosbutaca.Descripcion === "VIP") {
                            this.editorButacasSinNumerarForm.patchValue({
                              lblVIP: estadosbutaca.NumButaca,
                            });
                          } else if (
                            estadosbutaca.Descripcion === "VREDUCIDA"
                          ) {
                            this.editorButacasSinNumerarForm.patchValue({
                              lblVREDUCIDA: estadosbutaca.NumButaca,
                            });
                          } else if (estadosbutaca.Descripcion === "BLOQUEOS") {
                            this.editorButacasSinNumerarForm.patchValue({
                              lblBLOQUEOS: estadosbutaca.NumButaca,
                            });
                          } else if (estadosbutaca.Descripcion === "RESERVA") {
                            this.editorButacasSinNumerarForm.patchValue({
                              lblRESERVA: estadosbutaca.NumButaca,
                            });
                          }
                        }
                      }
                    );
                  })
                  .append("svg:title")
                  .text("Recinto no numerado.");
              }
            });

            d3.selectAll("path")
              .on("mouseover", function () {
                var path = d3.select(this);
                path.style("cursor", "default");
              })
              .on("wheel", function (d) {
                var path = d3.select(this);
                path.style("cursor", "zoom-in");
              });

            this.butacasCopia = butacas;
            this.svgDrawing = document.getElementById("mapaButacas");
            this.point = this.svgDrawing.createSVGPoint();
            this.svg_rect = this.svgDrawing.getBoundingClientRect();

            this.svgDrawing.addEventListener("pointerdown", (e) =>
              this.mouseButtonPressed(e)
            );
            this.svgDrawing.addEventListener("pointerup", (e) =>
              this.mouseButtonReleased(e)
            );
            this.svgDrawing.addEventListener("pointermove", (e) =>
              this.mouseMove(e)
            );
          });
      });
  }

  borrarSeleccion() {
    this.idsButacasMarcadas = [];
    this.servicioAPI.DesamarcarButacasBySesionId().subscribe((respuesta) => {
      this.ngOnInit();
    });
  }

  borraSeleccionSinRecarga() {
    this.idsButacasMarcadas = [];
    this.servicioAPI.DesamarcarButacasBySesionId().subscribe((respuesta) => {});
  }

  async guardarButacas() {
    const valor = this.editorButacasForm.value;
    this.servicioAPI
      .RecintosButacasCambioTipoEstado(valor.EditarTipo, valor.EditarEstado)
      .subscribe((respuesta) => {
        this.borrarSeleccion();
      });
  }

  async guardarButacasSinNumerar() {
    const valor = this.editorButacasSinNumerarForm.value;
    this.servicioAPI
      .RecintosButacasCambioTipoEstado(valor.EditarTipo, valor.EditarEstado)
      .subscribe((respuesta) => {
        this.borrarSeleccion();
      });
  }

  setZoom(direction) {
    //if (!this.multiselecction) {
    if (direction == "up") {
      this.lastTransform.k += 0.05;
    } else {
      this.lastTransform.k -= 0.05;
    }
    this.recinto.attr(
      "transform",
      "translate(" +
        this.lastTransform.x +
        "," +
        this.lastTransform.y +
        ") scale(" +
        this.lastTransform.k +
        ")"
    );
    //}
  }

  resetView() {
    this.lastTransform = this.originTransform;
    this.recinto.attr(
      "transform",
      "translate(" +
        this.lastTransform.x +
        "," +
        this.lastTransform.y +
        ") scale(" +
        this.lastTransform.k +
        ")"
    );
    this.originTransform = {
      k: this.origink,
      x: this.originx,
      y: this.originy,
    };
  }

  selectionToggle() {
    if (this.multiselecction) this.multiselecction = false;
    else this.multiselecction = true;
  }

  selectionMulti(modo) {
    this.multiselecction = modo;
  }

  async guardarButacasSinNumerarMenuEstado(estado) {
    const valor = this.editorButacasForm.value;
    this.loading = true;
    this.recinto.style("opacity", 0);
    let _that = this;
    this.servicioAPI
      .RecintosButacasCambioTipoEstado(estado, valor.EditarTipo)
      .subscribe((respuesta) => {
        this.loading = false;
        this.recinto.attr("transform", _that.lastTransform);
        this.borrarSeleccion();
      });
  }

  async guardarButacasSinNumerarMenuTipo(tipo) {
    const valor = this.editorButacasForm.value;
    this.loading = true;
    this.recinto.style("opacity", 0);
    let _that = this;
    this.servicioAPI
      .RecintosButacasCambioTipoEstado(valor.EditarEstado, tipo)
      .subscribe((respuesta) => {
        this.loading = false;
        this.recinto.attr("transform", _that.lastTransform);
        this.borrarSeleccion();
      });
  }

  resetearCantidadesNoNumeradas() {
    this.editorButacasSinNumerarForm.patchValue({ cantidadNORMAL: 0 });
    this.editorButacasSinNumerarForm.patchValue({ cantidadMINUSVALIDO: 0 });
    this.editorButacasSinNumerarForm.patchValue({ cantidadVIP: 0 });
    this.editorButacasSinNumerarForm.patchValue({ cantidadVREDUCIDA: 0 });
    this.editorButacasSinNumerarForm.patchValue({ cantidadBLOQUEOS: 0 });
    this.editorButacasSinNumerarForm.patchValue({ cantidadRESERVA: 0 });
  }

  eliminar(valor: string) {
    let cantidad = 0;
    const form = this.editorButacasSinNumerarForm.value;
    if (valor === "NORMAL") {
      cantidad = form.cantidadNORMAL;
    } else if (valor === "MINUSVALIDO") {
      cantidad = form.cantidadMINUSVALIDO;
    } else if (valor === "VIP") {
      cantidad = form.cantidadVIP;
    } else if (valor === "VREDUCIDA") {
      cantidad = form.cantidadVREDUCIDA;
    } else if (valor === "BLOQUEOS") {
      cantidad = form.cantidadBLOQUEOS;
    } else if (valor === "RESERVA") {
      cantidad = form.cantidadRESERVA;
    }
    if (cantidad <= 0) {
      cantidad = 1;
    }
    cantidad--;
    const marcado = [
      {
        Cantidad: cantidad,
        idTarifa: 0,
      },
    ];
    let fecha = moment(
      this.espectaculosService.sesionSeleccionada.fechaSesion,
      "DD/MM/YYYY"
    ).format("YYYY/MM/DD");
    this.servicioAPI
      .MarcarDesmarcarButacaNoNumeradaAsync(
        this.recintoNoNumeradoSeleccionado,
        this.espectaculosService.sesionSeleccionada.espectaculoId,
        fecha,
        this.espectaculosService.sesionSeleccionada.horaInicioSesion,
        valor,
        marcado
      )
      .subscribe((respuesta) => {
        if (
          respuesta !== undefined &&
          respuesta.DatosResult &&
          respuesta.DatosResult.Butacas.length >= 0
        ) {
          this.noNumeradasSetCantidad(valor, cantidad);
        } else {
          this._snackBar.open("Error al liberar la butaca", null, {
            duration: 4000,
            horizontalPosition: "center",
            verticalPosition: "top",
          });
        }
      });
  }

  agregar(valor: string) {
    let cantidad = 0;
    const form = this.editorButacasSinNumerarForm.value;
    if (valor === "NORMAL") {
      cantidad = form.cantidadNORMAL;
    } else if (valor === "MINUSVALIDO") {
      cantidad = form.cantidadMINUSVALIDO;
    } else if (valor === "VIP") {
      cantidad = form.cantidadVIP;
    } else if (valor === "VREDUCIDA") {
      cantidad = form.cantidadVREDUCIDA;
    } else if (valor === "BLOQUEOS") {
      cantidad = form.cantidadBLOQUEOS;
    } else if (valor === "RESERVA") {
      cantidad = form.cantidadRESERVA;
    }
    cantidad++;
    const marcado = [
      {
        Cantidad: cantidad,
        idTarifa: 0,
      },
    ];
    let fecha = moment(
      this.espectaculosService.sesionSeleccionada.fechaSesion,
      "DD/MM/YYYY"
    ).format("YYYY/MM/DD");
    this.servicioAPI
      .MarcarDesmarcarButacaNoNumeradaAsync(
        this.recintoNoNumeradoSeleccionado,
        this.espectaculosService.sesionSeleccionada.espectaculoId,
        fecha,
        this.espectaculosService.sesionSeleccionada.horaInicioSesion,
        valor,
        marcado
      )
      .subscribe((respuesta) => {
        if (
          respuesta !== undefined &&
          respuesta.DatosResult &&
          respuesta.DatosResult.Butacas.length > 0
        ) {
          this.noNumeradasSetCantidad(valor, cantidad);
        } else {
          this._snackBar.open("No puede marcar mas butacas", null, {
            duration: 4000,
            //panelClass: ['blue-snackbar'],
            horizontalPosition: "center",
            verticalPosition: "top",
          });
        }
      });
  }

  noNumeradasSetCantidad(valor: string, cantidad: number) {
    if (valor === "NORMAL") {
      this.editorButacasSinNumerarForm.patchValue({ cantidadNORMAL: cantidad });
    } else if (valor === "MINUSVALIDO") {
      this.editorButacasSinNumerarForm.patchValue({
        cantidadMINUSVALIDO: cantidad,
      });
    } else if (valor === "VIP") {
      this.editorButacasSinNumerarForm.patchValue({ cantidadVIP: cantidad });
    } else if (valor === "VREDUCIDA") {
      this.editorButacasSinNumerarForm.patchValue({
        cantidadVREDUCIDA: cantidad,
      });
    } else if (valor === "BLOQUEOS") {
      this.editorButacasSinNumerarForm.patchValue({
        cantidadBLOQUEOS: cantidad,
      });
    } else if (valor === "RESERVA") {
      this.editorButacasSinNumerarForm.patchValue({
        cantidadRESERVA: cantidad,
      });
    }
  }

  onChange(tipoN: string, event: any): void {
    let valor = event.target.value;
    const value = this.stringIsNumber(valor) ? Number(valor) : 0;
    if (value > 0 && Number.isInteger(value)) {
    } else {
      valor = "0";
    }
    const marcado = [
      {
        Cantidad: value,
        idTarifa: 0,
      },
    ];
    let fecha = moment(
      this.espectaculosService.sesionSeleccionada.fechaSesion,
      "DD/MM/YYYY"
    ).format("YYYY/MM/DD");
    this.servicioAPI
      .MarcarDesmarcarButacaNoNumeradaAsync(
        this.recintoNoNumeradoSeleccionado,
        this.espectaculosService.sesionSeleccionada.espectaculoId,
        fecha,
        this.espectaculosService.sesionSeleccionada.horaInicioSesion,
        tipoN,
        marcado
      )
      .subscribe((respuesta) => {
        if (
          respuesta !== undefined &&
          respuesta.DatosResult &&
          respuesta.DatosResult.Butacas.length >= 0
        ) {
          event.target.value = respuesta.DatosResult.Butacas.length;
        } else {
          this._snackBar.open("No puede marcar mas butacas", null, {
            duration: 4000,
            horizontalPosition: "center",
            verticalPosition: "top",
          });
        }
      });
  }

  stringIsNumber(s) {
    const x = +s;
    return x.toString() === s;
  }

  mostrarEditarSesion() {
    const urlSections = this.router.url.split("/");
    urlSections.pop();

    let backUrl = "";

    urlSections.forEach((section) => {
      backUrl += section + "/";
    });
    this.router.navigate([backUrl]);
  }

  goBack() {
    const urlSections = this.router.url.split("/");
    urlSections.pop();
    urlSections.pop();
    urlSections.pop();

    let backUrl = "";

    urlSections.forEach((section) => {
      backUrl += section + "/";
    });
    this.router.navigate([backUrl]);
  }

  traerEstado(estado) {
    let respuestaEstado = "";
    this.listaEstadosButacas.forEach((obj) => {
      if (obj.Id.toString() === estado.toString()) {
        respuestaEstado = obj.Nombre;
      }
    });
    return respuestaEstado;
  }

  setElement(ele) {
    this.previousElement = this.element;
    this.element = ele;
  }

  getNewAttributes() {
    var x = this.currentX < this.originX ? this.currentX : this.originX;
    var y = this.currentY < this.originY ? this.currentY : this.originY;
    var width = Math.abs(this.currentX - this.originX);
    var height = Math.abs(this.currentY - this.originY);
    return {
      x: x,
      y: y,
      width: width,
      height: height,
    };
  }

  getCurrentAttributes() {
    var x = +this.element.attr("x");
    var y = +this.element.attr("y");
    var width = +this.element.attr("width");
    var height = +this.element.attr("height");
    return {
      x1: x,
      y1: y,
      x2: x + width,
      y2: y + height,
    };
  }

  getCurrentAttributesAsText() {
    var attrs = this.getCurrentAttributes();
    return (
      "x1: " +
      attrs.x1 +
      " x2: " +
      attrs.x2 +
      " y1: " +
      attrs.y1 +
      " y2: " +
      attrs.y2
    );
  }

  focus() {
    this.element.style("stroke", "#DE695B").style("stroke-width", "2.5");
  }

  removeSelRect() {
    this.element.remove();
    this.element = null;
  }

  removePrevious() {
    if (this.previousElement) {
      this.previousElement.remove();
    }
  }

  mouseButtonPressed(evt) {
    if (this.multiselecction) {
      this.drawRectToSelect = null;
      let g = document.getElementById("recinto");
      this.svg_children = g.querySelectorAll("*");
      this.pointerOrigin = this.getPointFromEvent(evt);

      if (document.querySelector("#temp_selection"))
        document
          .querySelector("#temp_selection")
          .parentNode.removeChild(document.querySelector("#temp_selection"));

      if (this.drawRectToSelect) this.drawRectToSelect = null;

      if (evt.button === 0) {
        this.drawRectToSelect = d3
          .select("#mapaButacas")
          .append("rect")
          .attr("id", "temp_selection")
          .classed("selectionBox", true)
          .style("stroke", "blue")
          .style("fill", "none")
          .attr(
            "x",
            Math.round(this.pointerOrigin.x / this.raster) * this.raster
          )
          .attr(
            "y",
            Math.round(this.pointerOrigin.y / this.raster) * this.raster
          )
          .attr("height", this.raster)
          .attr("width", this.raster);
      }
    }
  }

  elementIsInside(el, box) {
    var result = false;
    let el_rect = el.getBoundingClientRect();
    let box_rect = box.getBoundingClientRect();
    if (
      el_rect.right >= box_rect.left &&
      el_rect.right <= box_rect.right &&
      el_rect.bottom >= box_rect.top &&
      el_rect.bottom <= box_rect.bottom
    ) {
      result = true;
    } else if (
      el_rect.left >= box_rect.left &&
      el_rect.left <= box_rect.right &&
      el_rect.bottom >= box_rect.top &&
      el_rect.bottom <= box_rect.bottom
    ) {
      result = true;
    } else if (
      el_rect.right >= box_rect.left &&
      el_rect.right <= box_rect.right &&
      el_rect.top >= box_rect.top &&
      el_rect.top <= box_rect.bottom
    ) {
      result = true;
    } else if (
      el_rect.left >= box_rect.left &&
      el_rect.left <= box_rect.right &&
      el_rect.top >= box_rect.top &&
      el_rect.top <= box_rect.bottom
    ) {
      result = true;
    }
    return result;
  }

  mouseButtonReleased(evt) {
    if (this.multiselecction) {
      this.svgDrawing.style.cursor = "pointer";
      if (this.drawRectToSelect) {
        let counter = 0;
        const box = document.querySelector("#temp_selection");
        this.loading = true;
        for (var i = 0; i < this.svg_children.length; i++) {
          if (this.elementIsInside(this.svg_children[i], box)) {
            if (this.svg_children[i].nodeName == "circle") {
              counter++;
              let labutaca = this.butacasCopia.find(
                (f) =>
                  f.ButacaDDBB &&
                  f.ButacaDDBB.idRecintoButaca ==
                    this.svg_children[i].attributes._pkid.nodeValue
              );
              if (labutaca) {
                let estadobutaca = labutaca.ButacaDDBB.Estado;
                if (estadobutaca != "3" && estadobutaca != "4") {
                  const marcado = [
                    {
                      RecintoButacaIds: labutaca.ButacaDDBB.idRecintoButaca,
                      idTarifa: 0,
                    },
                  ];
                }
              }
            }
          }          
        }
        if (document.querySelector("#temp_selection"))
          document
            .querySelector("#temp_selection")
            .parentNode.removeChild(document.querySelector("#temp_selection"));
        if (this.drawRectToSelect) this.drawRectToSelect = null;

        this.loading = false;
        this.drawRectToSelect = null;
        this.svg_children = null;
        this.pointerOrigin = null;
      }
    } else {
      if (document.querySelector("#temp_selection"))
        document
          .querySelector("#temp_selection")
          .parentNode.removeChild(document.querySelector("#temp_selection"));
      if (this.drawRectToSelect) this.drawRectToSelect = null;
      this.loading = false;
      this.drawRectToSelect = null;
      this.svg_children = null;
      this.pointerOrigin = null;
    }
  }

  mouseMove(evt) {
    if (this.multiselecction) {
      if (!this.drawRectToSelect) {
        return;
      }

      evt.preventDefault();

      var pointerPosition = this.getPointFromEvent(evt);
      if (this.drawRectToSelect) {
        let dtsx = Math.round(pointerPosition.x - this.pointerOrigin.x);
        let dtsy = Math.round(pointerPosition.y - this.pointerOrigin.y);

        if (dtsx >= 0 && dtsy >= 0)
          this.drawRectToSelect
            .attr(
              "width",
              Math.round(
                (pointerPosition.x - this.pointerOrigin.x) / this.raster
              ) * this.raster
            )
            .attr(
              "height",
              Math.round(
                (pointerPosition.y - this.pointerOrigin.y) / this.raster
              ) * this.raster
            );
      }
    }
  }

  getPointFromEvent(evt) {
    if (evt.targetTouches) {
      this.point.x = evt.targetTouches[0].clientX;
      this.point.y = evt.targetTouches[0].clientY;
    } else {
      this.point.x = evt.clientX;
      this.point.y = evt.clientY;
    }
    var invertedSVGMatrix = this.svgDrawing.getScreenCTM().inverse();

    return this.point.matrixTransform(invertedSVGMatrix);
  }

  marcarCirculo(marcado) {
    for (
      var i = 0;
      i < this.recinto.selectAll("circle")._groups[0].length;
      i++
    ) {
      console.log(marcado);
      if (
        this.recinto.selectAll("circle")._groups[0][i] &&
        this.recinto.selectAll("circle")._groups[0][i].attributes._pkid
          .nodeValue == marcado[0].RecintoButacaIds
      )
        this.recinto.selectAll("circle")._groups[0][i].style.fill = "blue";
    }
  }
}
